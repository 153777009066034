import { FormStateField } from '@energiebespaarders/hooks/useForm';
import { Radio, RadioGroup, Select } from '@energiebespaarders/symbols';
import { DropdownOption } from '@energiebespaarders/symbols/components/Select';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import React, { useCallback } from 'react';
import { Gender } from 'src/envPartner/PartnerRequest/PartnerRequestForm';

interface GenderInputProps {
  id: string;
  bgColor?: string;
  formState: FormStateField<Gender>;
  onChange: (value: Gender) => void;
  fontSize?: number;
  disabled?: boolean;
}

const GenderInput: React.FC<GenderInputProps> = ({
  bgColor,
  formState,
  onChange,
  fontSize,
  id,
  disabled = false,
}) => {
  const isMobile = useIsMobile();
  const handleChange = useCallback((value: Gender) => onChange(value), [onChange]);
  const handleDropdownChange = useCallback(
    (option: DropdownOption<Gender>) => onChange(option.value),
    [onChange],
  );
  return isMobile ? (
    <Select<Gender>
      label="Aanhef"
      options={[
        { label: 'Dhr.', value: 'male' },
        { label: 'Mevr.', value: 'female' },
        { label: 'Anders', value: 'other' },
      ]}
      onChange={handleDropdownChange}
    />
  ) : (
    <RadioGroup
      bgColor={bgColor}
      divider={3}
      error={formState.error}
      touched={formState.touched}
      label="Aanhef"
      labelColor="grayDarker"
      onChange={handleChange}
      fontSize={fontSize}
      disabled={disabled}
    >
      <Radio id={`${id}-male`} value="male" label="Dhr." checked={formState.value === 'male'} />
      <Radio
        id={`${id}-female`}
        value="female"
        label="Mevr."
        checked={formState.value === 'female'}
      />
      <Radio
        id={`${id}-other`}
        value="other"
        label="Anders"
        checked={formState.value === 'other'}
      />
    </RadioGroup>
  );
};

export default GenderInput;
