export type ThanksParams = {
  order?: string;
  constructionYear?: number;
  houseType?: number;
  requestType?: 'partnerRequest' | 'partnerSignup' | 'energyLabelRequest';
};

export type SelfServiceIntakeParams = ThanksParams & {
  firstName?: string;
  lastName?: string;
  email?: string;
};

export function encodeParams(params: Record<string, any>): string {
  return btoa(JSON.stringify(params));
}

export function decodeParams<T extends Record<string, any>>(asciiValue: string): T {
  try {
    const decodedString = atob(asciiValue);
    if (!decodedString) return {} as T;
    return JSON.parse(decodedString);
  } catch (e) {
    if (e.code === 5) console.error('Failed to decode string, wrong encoding');
    return {} as T;
  }
}
