import { Box } from '@energiebespaarders/symbols';
import { Color, Medium, Red, Small, Smaller } from '@energiebespaarders/symbols/helpers';
import styled from 'styled-components';
import HybridLink from '../HybridLink';

export const StyledHTMLCheckbox = styled.input<{ $hasError: boolean }>`
  appearance: none;
  -webkit-appearance: none;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  border: 1px solid ${props => (props.$hasError ? 'red' : 'black')};
  border-radius: 3px;
  transition: all 0.2s;

  &::before {
    content: '';
    width: 1em;
    height: 1em;
    transform: scale(0);
    background-color: white;
  }

  &:checked {
    background: ${x => x.theme.colors.green};
    border-color: ${x => x.theme.colors.greenDark};

    &::before {
      clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);
      transform: scale(1);
    }
  }
`;

interface ConsentCheckboxProps {
  checked: boolean;
  onToggle: (v: boolean) => void;
  error?: string;
}

const ConsentCheckbox: React.FC<ConsentCheckboxProps> = ({ checked, onToggle, error }) => {
  return (
    <Box mb={1}>
      <Smaller>
        <Color c="grayDarker">
          <p>
            Energiebespaarders verwerkt jouw persoonsgegevens om een verduurzamingsadvies op maat
            met offerte(s) op te stellen. Hiervoor hebben we jouw toestemming nodig. Zie ook onze{' '}
            <HybridLink to="/privacy" target="_blank">
              Privacyverklaring
            </HybridLink>{' '}
            voor meer informatie.{' '}
            {error && (
              <Medium>
                <Red>{error}</Red>
              </Medium>
            )}
          </p>
        </Color>
      </Smaller>
      <Small>
        <StyledHTMLCheckbox
          id="consentTerms-requestPackage"
          type="checkbox"
          checked={checked}
          onChange={e => onToggle(e.target.checked)}
          $hasError={!!error}
        />
        <label htmlFor="consentTerms-requestPackage" style={{ paddingLeft: 6, lineHeight: 1.2 }}>
          Ik geef Energiebespaarders toestemming om mijn persoonsgegevens te verwerken voor deze
          doeleinden.
        </label>
      </Small>
    </Box>
  );
};

export default ConsentCheckbox;
