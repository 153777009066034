import { Box, Separator } from '@energiebespaarders/symbols';
import { Color, Medium, Red, Small, Smaller } from '@energiebespaarders/symbols/helpers';
import HybridLink from '../HybridLink';
import { StyledHTMLCheckbox } from './ConsentCheckbox';

interface INGConsentCheckboxProps {
  checked: boolean;
  onToggle: (v: boolean) => void;
  error?: string;
}

const INGConsentCheckbox: React.FC<INGConsentCheckboxProps> = ({ checked, onToggle, error }) => {
  return (
    <Box mb={1}>
      <Smaller>
        <Separator my={2} color="grayLight" />
        <Color c="grayDarker">
          <p>
            Deze pilot is onderdeel van een samenwerking tussen ING en Energiebespaarders.
            Energiebespaarders deelt contactgegevens (NAWTE) met ING voor feedbackdoeleinden.
            Hiervoor hebben we jouw toestemming nodig. Zie ook onze{' '}
            <HybridLink to="/privacy" target="_blank">
              Privacyverklaring
            </HybridLink>{' '}
            voor meer informatie.{' '}
            {error && (
              <Medium>
                <Red>{error}</Red>
              </Medium>
            )}
          </p>
        </Color>
      </Smaller>
      <Small>
        <StyledHTMLCheckbox
          id="consentING-requestPackage"
          type="checkbox"
          checked={checked}
          onChange={e => onToggle(e.target.checked)}
          $hasError={!!error}
        />
        <label htmlFor="consentING-requestPackage" style={{ paddingLeft: 6, lineHeight: 1.2 }}>
          Ik geef Energiebespaarders toestemming om mijn contactgegevens (NAWTE) met ING te delen
          voor feedbackdoeleinden.
        </label>
      </Small>
    </Box>
  );
};

export default INGConsentCheckbox;
